@font-face {
  font-family: "ISO";
  font-weight: 500;
  src: local("ISO"), url(./fonts/ISOv0.7-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "ISO";
  font-weight: 900;
  src: local("ISO"), url(./fonts/ISOv0.7-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "ISO";
  font-weight: 700;
  src: local("ISO"), url(./fonts/ISOv0.7-Medium.woff2) format("woff2");
}

@font-face {
  font-family: "ISO";
  font-weight: 300;
  src: local("ISO"), url(./fonts/ISOv0.7-Light.woff2) format("woff2");
}

body {
  margin: 0;
  color: #000;
  font-family: "ISO", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.boldDesc {
  font-weight: 900;
}

.rowRature {
  padding: 0 56px;
  max-width: 1000px;
  margin: auto;
}

#radioPlayButton {
  margin: auto;
  padding: 2rem 0;
  width: 50%;
  cursor: pointer;
}

#player {
  height: 125px;
  display: flex;
  justify-content: flex-start;
  padding: 0 56px;
}

#player p {
  line-height: 1.25rem;
}

.audioInfosContainer {
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audioInfosContainer p {
  font-size: 16px;
  margin-bottom: 0;
}

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: inherit;
  text-decoration: underline;
}

.navbar-light .navbar-toggler {
  border-color: #000000;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.8%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-toggler {
  border-color: #ffbcd2;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 188, 210, 0.8%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .sonique-toggle {
  border-color: #ffed00;
}

.sonique-link:focus,
.navbar-toggler:focus {
  outline: none;
}

.navbar-dark .sonique-toggle .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 237, 0, 0.8%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.dropdown {
  padding-left: 0.5rem;
}

.dropdown-menu {
  margin: 0;
  padding: 0;
  background-color: #ffbcd2;
  border-top: 3px solid #000;
  border-radius: 0;
  font-size: inherit;
  font-weight: 500;
}

.dropdown-item {
  border-bottom: 3px solid #000;
  border-left: 3px solid #000;
  border-right: 3px solid #000;
  padding: 0.75rem;
  font-size: 1.25rem;
  text-align: center;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: inherit;
}

#nav-dropdown {
  padding: 0;
}

.navbar-dark #nav-dropdown {
  color: #ffbcd2;
}

.navbar-dark .dropdown-menu {
  margin: 0;
  padding: 0;
  background-color: #000000;
  border-top: 3px solid #ffbcd2;
  border-radius: 0;
  font-size: inherit;
  font-weight: 500;
}

.navbar-dark .dropdown-item {
  border-bottom: 3px solid #ffbcd2;
  border-left: 3px solid #ffbcd2;
  border-right: 3px solid #ffbcd2;
  padding: 0.75rem;
  font-size: 1.25rem;
  text-align: center;
  color: #ffbcd2;
}

#submenu {
  text-align: center;
}

.menuContainer {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.25rem;
}

.newsletter {
  margin-bottom: 1rem;
}
.newsletter input {
  background-color: rgb(255, 188, 210);
  border: 3px solid rgb(255, 188, 210);
  border-radius: 0px;
  width: 100%;
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  color: #495057;
  font-weight: 400;
  font-size: 1rem;
  height: calc(1.5em + 0.75rem + 2px);
}

.newsletter input::placeholder {
  color: #6c757d;
  opacity: 1;
}

.newsletter button {
  color: #ffbcd2;
  background-color: #000000;
  border: 3px solid #ffbcd2;
  border-radius: 0;
  font-size: 1.5rem;
  font-weight: 700;
  width: 180px;
  padding: 0.375rem 0.75rem;
}
.podcastDetailScreenContainer {
  padding-top: 154px;
}

.episodeContainer {
  display: flex;
  padding-block-end: 1.75rem;
}

.mobileShow {
  display: none;
}
.mobileHide {
  visibility: visible;
  display: flex;
}

@media (max-width: 575.98px) {
  #radioPlayButton {
    padding-top: 0;
    width: 75%;
  }

  .mobileShow {
    display: inline;
  }

  .mobileHide {
    display: none;
  }

  .rowRature {
    padding: 0 1vw;
  }

  .episodeContainer {
    display: flex;
    flex-direction: column;
    align-items: left;
  }

  .podcastDetailScreenContainer {
    padding-top: 96px;
  }

  .podcastsIconsMobileMargin {
    margin-bottom: 4vh;
  }
  .mobilePadding {
    padding-top: 0.5rem;
  }

  #player {
    padding: 0 1vw;
  }
}

@media (max-width: 991px) {
  #submenu {
    text-align: left;
    display: flex;
  }

  .menuContainer {
    margin-left: 0;
    margin-right: 0;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .soniquelinkButton {
    padding: 12px 0;
  }

  .socialIconsMobilePadding {
    margin-bottom: 1.5rem;
  }
}

@media (min-width: 992px) {
  .submenuWrap {
    width: 70%;
  }
}
