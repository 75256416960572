@font-face {
  font-family: "ISO";
  src: local("ISO"), url(./fonts/ISOv0.7-Regular.woff) format("woff");
}

body {
  margin: 0;
  font-family: "ISO", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
